<script>
import { required } from 'vuelidate/lib/validators';
import appConfig from '@/app.config';
import systemService from '@/api/systemService';

export default {
  page: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      backgroundImageUrl: require('@/assets/images/login-background.jpg'),
      phone_number: null,
      password: null,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    phone_number: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    containerStyles() {
      return {
        backgroundImage: `url(${this.backgroundImageUrl})`
      };
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add('authentication-bg');
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            systemService.login({
              username: this.phone_number,
              password: this.password,
            }).then(() => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                
                this.$store.dispatch('authfack/login', {
                  phone_number: this.phone_number,
                  password: this.password,
                });
              })
              .catch((error) => {
                this.tryingToLogIn = false;
                const { response: { data: { message } } } = error;
                this.authError = message ? message : '';
                this.isAuthError = true;
              })
          );
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="container-login" :style="containerStyles">
      <div class="card-login">
        <img src="@/assets/images/logo.png" alt="bitbucket" width="270" />
        <div class="wrap-from">
          <b-alert
            v-model="isAuthError"
            variant="danger"
            class="mt-3"
            dismissible
            >{{ authError }}</b-alert
          >

          <div
            v-if="notification.message"
            :class="'alert ' + notification.type"
          >
            {{ notification.message }}
          </div>

          <b-form @submit.prevent="tryToLogIn">
            <b-form-group id="input-group-1" class="mb-3" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="phone_number"
                type="text"
                placeholder="Email / Phone number"
                :class="{ 'is-invalid': submitted && $v.phone_number.$error }"
              ></b-form-input>
              <div
                v-if="submitted && $v.phone_number.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.phone_number.required"
                  >Phone number is required.</span
                >
                <span v-if="!$v.phone_number.phone_number"
                  >Please enter valid Phone number.</span
                >
              </div>
            </b-form-group>

            <b-form-group id="input-group-2" class="mb-3">
              <b-form-input
                id="input-2"
                v-model="password"
                type="password"
                placeholder="Password"
                :class="{
                  'is-invalid': submitted && $v.password.$error,
                }"
              ></b-form-input>

              <div
                v-if="submitted && !$v.password.required"
                class="invalid-feedback"
              >
                Password is required.
              </div>
            </b-form-group>
            <div class="wrap-button">
              <button type="submit" class="button">
                Log In
              </button>
            </div>
            <div class="forgot-password">
              <router-link to="/account/forgot-password" class="text-muted"
                >Forgot password?</router-link
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module></style>
